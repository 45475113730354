<template>
  <v-footer id="donate" class="white my-0">
    <div class="ml-10 d-flex">
      <v-btn
        class="ma-2"
        icon
        href="https://twitter.com/osterheider"
        target="_blank"
        color="#1DA1F2"
      >
        <v-icon>
        mdi-twitter
      </v-icon>
      </v-btn>
    </div>
    <v-spacer></v-spacer>
    <div class="py-4 text-body-2">{{$t('address_label')}}: {{$t('address')}}</div>
    <v-spacer></v-spacer>
    <div class="mx-10 py-4 text-body-2">&copy; {{$t('copyright')}} 2022<br>Build version: v{{versionNumber}}</div>
  </v-footer>
</template>
<script>
export default {
  name:'footerApp',
  props: ['versionNumber']
}
</script>