<template>
  <div>
    <v-app-bar height="80px"
               color="white"
               light
               app
               fixed
               elevation="1"
               class="px-0 px-md-12"
    >
      <div>
        <a href="/">
        <v-img src="@/assets/logo.svg" width="150px"
               contain>
        </v-img>
        </a>
      </div>
      <v-spacer/>
      <a href="/" style="text-decoration: none">
      <v-btn class="text-none font-weight-bold ml-5 d-none d-sm-block"
        text>
        Einstein App
      </v-btn>
      </a>
      <a href="/docs" style="text-decoration: none">
      <v-btn class="text-none font-weight-bold ml-5 d-none d-sm-block" text>
        Docs & Roadmap
      </v-btn>
      </a>
      <a href="#donate" style="text-decoration: none">
      <v-btn class="ml-2" color="errorLight d-none d-sm-block"
       depressed
       dark>
        Donate
      </v-btn>
      </a>
      <v-app-bar-nav-icon @click="drawer=!drawer" class="d-block d-sm-none">

      </v-app-bar-nav-icon>
    </v-app-bar>
    <div class="d-block d-sm-none">
      <v-navigation-drawer v-model="drawer" right fixed>
        <v-list>
          <v-list-item href="/">
            <v-list-item-title>
              {{ $t('einstein_app') }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item href="/docs">
            <v-list-item-title>
              {{ $t('getting_started') }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
        <template v-slot:append>
          <div class="pa-2">
            <a href="#donate" style="text-decoration: none">
              <v-btn color="errorLight" depressed dark block>
                {{ $t('donate') }}
              </v-btn>
            </a>
          </div>
        </template>
      </v-navigation-drawer>
    </div>

  </div>

</template>

<script>
export default {
  name: 'app-header',

  data() {
    return {
      drawer:false
    }
  }

}
</script>
<style>
.v-toolbar__title {
  font-size: 32px !important;
  color: indigo;
  font-weight: bold;
}
</style>