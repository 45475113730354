<template>
  <v-app>
    <HeaderApp/>
    <v-main>
      <v-container fluid class="mt-0 mb-5 py-md-12">
        <v-row align="center">
          <v-col cols="12"
                 md="4"
                 offset-md="2"
                 class="d-flex flex-column align-center"
          >
            <h7 class="text-h7 font-weight-medium ripe--text mb-2">First, choose your Liquidity Pool:</h7>
            <v-autocomplete
                :items="pools"
                outlined
                :label="$t('choose_pool') "
                item-text="displaySymbol"
                v-model="poolSelected"
                return-object
                @change="poolSelect"
                auto-select-first
            ></v-autocomplete>
          </v-col>
          <v-col  cols="12"
                  md="4"
                  class="d-flex flex-column align-center"
          >
            <v-sheet
              rounded
              class="mx-auto pa-5"
              align="center"
              height="100%"
              width="100%"
              color="grey lighten-4"
            >
             <v-btn-toggle
              v-model="strategy"
              tile
              color="ripe accent-3"
              group
              class="align-center"
             >
              <v-btn value="harvest">
                Harvest
              </v-btn>

              <v-btn value="compound">
                Compound
              </v-btn>

              <v-btn value="keep">
                Keep DFI
              </v-btn>
            </v-btn-toggle>
            <v-divider></v-divider>
              <v-radio-group
                v-model="compoundingTimeframe"
                v-if="strategy === 'compound'"
                label="How often do you reinvest your rewards?"
              >
                <v-radio
                  label="Every Week"
                  value="weekly"
                  color="ripe"
                ></v-radio>
                <v-radio
                  label="Every Month"
                  value="monthly"
                  color="ripe"
                ></v-radio>
                <v-radio
                  label="Every 3rd Month"
                  value="quarterly"
                  color="ripe"
                ></v-radio>
              </v-radio-group>
              <v-text-field v-if="strategy === 'keep'"
                            v-model="futureDfiPrice"
                            label="Future DFI price (in $) "
                            dense
                            outlined
                            class="no-shadow mt-5"
                            type="number"
                            min="0"
                            hide-details></v-text-field>
              <v-switch
                v-model="editCurrent"
                flat
                color="ripe"
                label="Edit current prices"
                class="mb-0"
              ></v-switch>
              <v-switch
                v-model="diminishingRewards"
                flat
                color="ripe"
                label="Diminishing block rewards"
                class="my-0"
              ></v-switch>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row v-if="Object.keys(poolSelected).length>0" class="justify-center text my-10 d-flex flex-column">
          <v-col cols="3"
                 md="6"
                 align-self="center"
          >
          <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row v-if="Object.keys(poolSelected).length>0">
          <v-col cols="12"
                 md="8"
          >
            <v-row>
              <v-spacer/>
              <v-col cols="5" sm="3" lg="2">
                <v-btn color="sky" block depressed class="pointer-none" dark>
                  {{ tokenA.displaySymbol }}
                </v-btn>
              </v-col>
              <v-col cols="7" sm="5" lg="4">
                <v-text-field v-if="!editCurrent"
                              :label="$t('current_price') "
                              dense
                              outlined
                              background-color="readOnly"
                              class="no-shadow"
                              type="number"
                              readonly
                              v-model="tokenAPrice"
                              hide-details></v-text-field>
                <v-text-field v-if="editCurrent"
                              :label="$t('current_price') "
                              dense
                              outlined
                              class="no-shadow"
                              type="number"
                              v-model="tokenAPrice"
                              hide-details></v-text-field>
              </v-col>
              <v-spacer/>
            </v-row>
            <v-row>
              <v-spacer/>
              <v-col cols="5" sm="3" lg="2">
                <v-btn color="ripe" block depressed class="pointer-none" dark>
                  {{ tokenB.displaySymbol }}
                </v-btn>
              </v-col>
              <v-col cols="7" sm="5" lg="4">
                <v-text-field v-if="!editCurrent"
                              :label="$t('current_price') "
                              dense
                              outlined
                              min="0"
                              background-color="readOnly"
                              class="no-shadow"
                              type="number"
                              readonly
                              v-model="tokenBPrice"
                              hide-details></v-text-field>
                <v-text-field v-if="editCurrent"
                              :label="$t('current_price') "
                              dense
                              outlined
                              min="0"
                              class="no-shadow"
                              type="number"
                              v-model="tokenBPrice"
                              hide-details></v-text-field>
              </v-col>
              <v-spacer/>
            </v-row>
            <v-row class="pt-4 pb-2">
              <v-spacer/>
              <v-col cols="5" sm="3" lg="2">
                <v-btn color="sky" block depressed class="pointer-none" dark>
                  {{ tokenA.displaySymbol }}
                </v-btn>
              </v-col>
              <v-col cols="7" sm="5" lg="4">
                <v-text-field :label="$t('future_price') "
                              dense
                              outlined
                              min="0"
                              v-model="tokenAFuturePrice"
                              class="no-shadow"
                              type="number"
                              hide-details>

                </v-text-field>
              </v-col>
              <v-spacer/>
            </v-row>
            <v-row>
              <v-spacer/>
              <v-col cols="5" sm="3" lg="2">
                <v-btn color="ripe" block depressed class="pointer-none" dark>
                  {{ tokenB.displaySymbol }}
                </v-btn>
              </v-col>
              <v-col cols="7" sm="5" lg="4">
                <v-text-field :label="$t('future_price') "
                              dense
                              outlined
                              min="0"
                              class="no-shadow"
                              type="number"
                              v-model="tokenBFuturePrice"
                              hide-details>

                </v-text-field>
              </v-col>
              <v-spacer/>
            </v-row>

            <v-row class="align-center text-center justify-center">
              <v-col cols="12">
                <h5 class="text-h5 pt-4 pt-lg-10">{{ $t('time_horizon') }}</h5>
              </v-col>
              <v-col cols="12" md="4">
                <v-slider
                    v-model="month"
                    step="1"
                    max="36"
                    thumb-label
                    :thumb-color="color.dfi"
                    :color="color.dfi"
                    :track-color="color.sky"
                ></v-slider>
                <div>{{ month }} {{ $t('months') }}</div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12"
                 md="4"
          >
            <div class="pb-4">
              <h6 class="text-h6 font-weight-bold">{{ $t('pool_apr') }}</h6>
              <h5 class="text-h5 font-weight-bold ripe--text ml-15">
                {{ (poolApr * 100).toFixed(2) }}%
              </h5>
            </div>
            <div class="pb-4">
              <h6 class="text-h6 font-weight-bold">{{ $t('impermanent_loss') }}</h6>
              <h5 class="text-h5 font-weight-bold sky--text ml-15">
                {{ A8 ? (A8 * 100).toFixed(2) : 0 }}%</h5>
            </div>
            <div class="pb-4">
              <h6 class="text-h6 font-weight-bold">{{ $t('actual_return') }}
                <v-tooltip
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                      <v-icon color="grey" small v-bind="attrs"
                      v-on="on">
                        mdi-information-outline
                      </v-icon>
                  </template>
                  <span><b>Does not include compounding and diminishing rewards over time.</b><br>Check our docs for more information.</span>
                </v-tooltip>
              </h6>
              <div class="d-flex">
                <h5 class="text-h5 font-weight-bold ripe--text ml-15">
                  {{ A15 ? (A15 * 100).toFixed(2) : 0 }}%</h5>
                <h6 class="text-h6 ripe--text ml-2">{{ $t('yearly') }}</h6>
              </div>
              <div class="d-flex">
                <h5 class="text-h5 font-weight-bold ripe--text ml-15">
                  {{ A18 ? (A18 * 100).toFixed(2) : 0 }}%
                </h5>
                <h6 class="text-h6 ripe--text ml-2">{{ $t('your_timehorizon') }}</h6>
              </div>
            </div>
            <div class="pb-4">
              <h6 class="text-h6 font-weight-bold">{{ $t('actual_return_wo_lm') }}</h6>
              <h5 class="text-h5 font-weight-bold sky--text ml-15">
                {{ A21 ? (A21 * 100).toFixed(2) : 0 }}%</h5>
            </div>
          </v-col>
        </v-row>
        <v-row class="text-center justify-center my-10 d-flex flex-column"
               v-if="Object.keys(poolSelected).length>0">
          <h6 class="text-h6 font-weight-light">
            {{ $t('your_investment') }}
          </h6>
          <div class="my-2 d-flex justify-center">
            <v-text-field type="number"
                          solo
                          outlined
                          :placeholder="investment_placeholder"
                          min="0"
                          style="max-width: 200px;"
                          v-model="investment">
            </v-text-field>
          </div>
          <h5 class="text-h5 pt-10 font-weight-bold">
            {{ $t('total_value_if_hold') }}
          </h5>
          <div class="d-flex text-center justify-center pt-1">
            <h4 class="text-h4 ripe--text font-weight-bold">
              $ {{ D24 ? numberWithCommas(D24.toFixed(2)) : 0 }}
            </h4>
            <h6 class="text-h6 pl-4 pt-2" :class="G24<0?'red--text':'success--text'">
              {{ G24 > 0 ? '+' : '' }}{{ G24 && G24 != 0 ? numberWithCommas(G24.toFixed(2)) : '' }}
            </h6>
          </div>

          <h5 class="text-h5 pt-5 font-weight-bold px-2">
            {{ $t('total_value_if_invested') }} {{ month }} {{ $t('month') }}
          </h5>
          <div class="d-flex text-center justify-center pt-1">
            <h4 class="text-h4 ripe--text font-weight-bold">$ {{ actualReturnIndiviual ? numberWithCommas(actualReturnIndiviual.toFixed(2)) : 0 }} </h4>
            <h6 class="text-h6 pl-4 pt-2" :class="G21<0?'red--text':'success--text'">
              {{ G21 > 0 ? '+' : '' }}{{ G21 && G21 != 0 ? numberWithCommas(G21.toFixed(2)) : '' }}
            </h6>
          </div>
        </v-row>
        <v-row class="justify-center text mt-10 d-flex flex-column">
          <v-col cols="3"
                 md="6"
                 align-self="center"
          >
          <v-divider></v-divider>
          </v-col>
          <v-col cols="12"
                 md="4"
                 align-self="center"
          >
            <h5 class="text-center text-h6 pt-4 pb-3 pt-lg-10 pb-lg-10">{{ $t('docs') }} <a href="/docs">here</a></h5>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <b>How to?</b>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="text-body-2">
                  <br>
                  Choose a pool, enter your future prices and adjust your timehorizon.<br><br>
                  DeFiChain Einstein will compute the impermanent loss when invested into Liquidity Mining. It will also provide you a simple estimation if you still gonna make a fortune with Liquidity Mining rewards or not.<br><br>

                  - That's it.<br><br>
                  
                  <v-divider></v-divider><br>

                  <b>Check our <a href="/docs">docs</a> page for more information and our roadmap.</b><br>
                  <v-select
                      v-model="apiSelected"
                      :items="apis"
                      solo
                      class="mt-4"
                      label="Choose an Ocean API"
                      item-text="name"
                      return-object
                      @change="selectOceanAPI"
                      auto-select-first
                  ></v-select>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <div class="text-center">
              <h5 class="text-h8 pt-8 pt-lg-10 pb-lg-1">{{ $t('disclaimer_label') }}</h5>
              <p><small>{{ $t('disclaimer') }}</small></p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-divider></v-divider>
    <footer-app :versionNumber=appVersion />
  </v-app>
</template>

<script>
import HeaderApp from "@/components/app-header";
import axios from 'axios'
import FooterApp from "@/components/footerApp";
import { version as packageVersion } from '../../../package.json';

export default {
  name: 'App',

  components: {
    FooterApp,
    HeaderApp,

  },
  async mounted() {
    await this.getPools()
    this.pools.sort()
  },
  computed: {
    L1() {
      return this.investment ? Number(this.investment) : 5000
    },
    A12() {
      return this.poolApr ? this.poolApr : 0
    },
    A1() {
      return this.L1 / this.B1 / 2
    },
    B1() {
      return this.tokenAPrice ? this.tokenAPrice : 0
    },
    C1() {
      return this.L1 / this.D1 / 2
    },
    D1() {
      return this.tokenBPrice ? this.tokenBPrice : 0
    },
    B4() {
      return this.tokenAFuturePrice ? this.tokenAFuturePrice : 0
    },
    D4() {
      return this.tokenBFuturePrice ? this.tokenBFuturePrice : 0
    },
    E1() {
      return (this.B4 / this.B1) - 1
    },
    F1() {
      return (this.D4 / this.D1) - 1
    },
    A4() {
      return this.A1 * this.C1 / Math.sqrt(this.A1 * this.C1 * this.B1 * (1 + this.E1) / (this.D1 * (1 + this.F1)))
    },
    C4() {
      return Math.sqrt(this.A1 * this.C1 * this.B1 * (1 + this.E1) / (this.D1 * (1 + this.F1)))
    },
    A8() {
      return -(((this.A4 * this.B4 + this.C4 * this.D4) / (this.A1 * this.B4 + this.C1 * this.D4)) - 1)
    },
    A15() {
      return (this.actualReturnYearly / this.L1) - 1
    },
    A18() {
      return (this.actualReturnIndiviual / this.L1) - 1
    },
    A21() {
      return (this.D24 / this.L1) - 1
    },
    D15() {
      return this.A4 * this.B4 + this.C4 * this.D4
    },
    D24() {
      return this.A1 * this.B4 + this.C1 * this.D4
    },
    G21() {
      return this.actualReturnIndiviual - this.L1
    },
    G24() {
      return this.D24 - this.L1
    },
    ComputedFutureDfiPrice() {
      return this.futureDfiPrice ? this.futureDfiPrice : 0
    },
    ComputedCurrentDfiPrice() {
      return this.currentDfiPrice ? this.currentDfiPrice : 0
    },
    actualReturnIndiviual() {
      return this.actualReturn(this.month)
    },
    actualReturnYearly() {
      return this.actualReturn(12)
    }
  },

  //
  // Initiate all data objects
  //
  data: () => ({
    apis: [
      { name: 'Sternberg Partners API', url: 'https://ocean.sternberg-partners.de/v0.0/mainnet/' },
      { name: 'Official Ocean API', url: 'https://ocean.defichain.com/v0/mainnet/' },
      { name: 'mydefichain API', url: 'https://ocean.mydefichain.com/v0/mainnet/' },
    ],
    apiSelected: { name: 'Official Ocean API', url: 'FL' },
    pools: [],
    poolSelected: {},
    tokenA: {},
    tokenB: {},
    tokenAPrice: 0.0,
    tokenBPrice: 0.0,
    tokenAFuturePrice: 0.0,
    tokenBFuturePrice: 0.0,
    month: 24,
    poolApr: 0.0,
    investment: null,
    investment_placeholder: "5000",
    rewardReduction: 0.01658,
    rewardReductionWeeks: 1.621527,
    editCurrent: false,
    diminishingRewards: false,
    currentDfiPrice: 0.0,
    futureDfiPrice: 0.0,
    strategy: "harvest",
    compoundingTimeframe: "weekly",
    color: { sky: '#87cefa', dfi: '#ff00af' },
    appVersion: packageVersion,
  }),

  //
  // All methods
  //
  methods: {

    //
    // Initally set values based on pool data from Ocean API
    //
    async poolSelect() {
      this.tokenA = this.poolSelected.tokenA;
      this.tokenB = this.poolSelected.tokenB;
      try {

        // Check for DUSD-DFI pool
        if (this.tokenA.symbol === 'DUSD') {
          this.tokenAPrice = 1.00;
          this.tokenAFuturePrice = 1.00;
          this.tokenBPrice = parseFloat(this.poolSelected.priceRatio.ab).toFixed(2);
          this.tokenBFuturePrice = this.tokenBPrice;
        }
        
        // Check for XYZ-DUSD pools
        else if (this.tokenB.symbol === 'DUSD') {
          this.tokenAPrice = parseFloat(this.poolSelected.priceRatio.ba).toFixed(2);
          this.tokenAFuturePrice = this.tokenAPrice;
          this.tokenBPrice = 1.00;
          this.tokenBFuturePrice = 1.00;
        }

        // All other pools
        else {
          const res1 = await this.getPrice(this.tokenA.symbol)
          this.tokenAPrice = parseFloat(res1.price.aggregated.amount).toFixed(2);
          this.tokenAFuturePrice = this.tokenAPrice;
          const res2 = await this.getPrice(this.tokenB.symbol)
          this.tokenBPrice = parseFloat(res2.price.aggregated.amount).toFixed(2);
          this.tokenBFuturePrice = this.tokenBPrice;
        }

        // Current pool APR
        this.poolApr = this.poolSelected.apr.reward + this.poolSelected.apr.commission;

        // Init of current & future DFI price
        const res3 = await this.getPrice('DFI');
        this.currentDfiPrice = parseFloat(res3.price.aggregated.amount).toFixed(2);
        this.futureDfiPrice = parseFloat(res3.price.aggregated.amount).toFixed(2);

      } catch (e) {
        console.log(e)
      }
    },

    //
    // Fetch oracle price of a specific token
    //
    async getPrice(token) {
      try {
        const res = await axios.get('prices/' + token + '-USD')
        return res.data.data
      } catch (e) {
        console.log(e)
      }
    },

    //
    // Fetch all available DEX poolpairs
    //
    async getPools() {
      try {
        const res = await axios.get('poolpairs?size=100')
        this.pools = res.data.data;
        this.sortPools()
      } catch (e) {
        console.log(e)
      }
    },

    //
    // Add 1000* seperating character to large numbers
    //
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    //
    // Sort poolpairs by DisplaySymbol (e.g. dBTC-DFI)
    //
    sortPools() {
      this.pools.sort(this.getSortOrder("displaySymbol"))
    },

    //
    // Sort helper function for "sortPools"
    //
    getSortOrder(prop) {    
      return function(a, b) {    
          if (a[prop].toLowerCase() > b[prop].toLowerCase()) {    
              return 1;    
          } else if (a[prop].toLowerCase() < b[prop].toLowerCase()) {    
              return -1;    
          }    
          return 0;    
      }
    },

    //
    // Switch to different Ocean API
    //
    selectOceanAPI() {    
      try {
        axios.defaults.baseURL = this.apiSelected.url
        this.getPools()
      } catch (e) {
        console.log(e)
      }
    },

    //
    // Calculate the actual retunrs based on timehorizon, diminishing block rewards, compounding and more
    //
    actualReturn (months) {
      let result = this.L1
      let apr = this.poolApr
      let poolChange = this.D15 - this.L1
      let times = 0
      let timesPerYear = 0
      let timesPerWeek = 0
      let totalDFI = 0
      let DFIchange = parseFloat(this.futureDfiPrice - this.currentDfiPrice)

      if(this.strategy == "harvest")
      {
        times = months * 365 / 12
        timesPerYear = 365
        timesPerWeek = 7

        if(times >= 1){
          for(let i = 1; i <= times; i++)
          {
            if(this.diminishingRewards)
            {
              apr = this.poolApr * (Math.pow(1-this.rewardReduction, Math.floor((i/timesPerWeek)/this.rewardReductionWeeks)))
            }
            result = result + ((this.L1 + (poolChange * (i / times))) * (apr / timesPerYear))
          }
          console.log(result)
          result = result + ((this.L1 + poolChange) * ((apr / timesPerYear) * (times % 1)))
          console.log(result)
          result = result + poolChange
        }
        else {
          result = this.D15 + (((this.L1 + this.D15) / 2) * (apr * (months / 12)))
        }
      }
      else if (this.strategy == "compound")
      {
        let daysPerTime = 0
        let days = months * 365 / 12
        timesPerYear = 365
        timesPerWeek = 7
        if(this.compoundingTimeframe == "daily")
        {
          daysPerTime = 1
        }
        else if(this.compoundingTimeframe == "weekly")
        {
          daysPerTime = 7
        }
        else if(this.compoundingTimeframe == "monthly")
        {
          daysPerTime = 365 / 12
        }
        else if(this.compoundingTimeframe == "quarterly")
        {
          daysPerTime = 365 / 4
        }

        if(days >= 1) {
          let midtermRewards = 0.0
          for(let i = 1; i <= days; i++)
          {
            if(this.diminishingRewards)
            {
              apr = this.poolApr * (Math.pow(1-this.rewardReduction, Math.floor((i/timesPerWeek)/this.rewardReductionWeeks)))
            }

            midtermRewards = midtermRewards + ((result + (poolChange * (i / days))) * (apr / 365))

            if((i % daysPerTime) < 1)
            {
              result = result + midtermRewards
              midtermRewards = 0.0
            }
          }
          result = result + midtermRewards
          result = result + ((result + poolChange) * ((apr / timesPerYear) * (days % 1)))
          result = result + poolChange
        }
        else {
          result = this.D15 + (((this.L1 + this.D15) / 2) * (apr * (months / 12)))
        }
      }
      else if (this.strategy == "keep")
      {
        times = months * 365 / 12
        timesPerYear = 365
        timesPerWeek = 7

        if(times >=1){
          for(let i=1; i <= times; i++)
          {
            if(this.diminishingRewards)
            {
              apr = this.poolApr * (Math.pow(1-this.rewardReduction, Math.floor((i/timesPerWeek)/this.rewardReductionWeeks)))
            }
            let adjustion = parseFloat((i / times) * DFIchange) // Ensure float type due to earlier type issues
            let current = parseFloat(this.currentDfiPrice) // Ensure float type due to earlier type issues
            let adjustedPrice = parseFloat(current + adjustion) // Ensure float type due to earlier type issues
            totalDFI = totalDFI + ((this.L1 + (poolChange * (i / times))) * (apr / timesPerYear)) / adjustedPrice
          }
          totalDFI = totalDFI + ((this.L1 + poolChange) * ((apr / timesPerYear) * (times % 1))) / (this.futureDfiPrice)
          result = this.D15 + (totalDFI * this.futureDfiPrice)
        }
        else {
          result = this.D15 + (totalDFI * this.futureDfiPrice)
        }
      }
      return result
    }
  }
};
</script>
<style lang="scss">

</style>
